export function ReportIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.667 10C1.667 5.4 5.4 1.665 10 1.665A8.332 8.332 0 0118.333 10 8.33 8.33 0 0110 18.333a8.336 8.336 0 01-8.333-8.334zm7.6-3.159c0-.399.333-.733.733-.733.4 0 .725.334.725.733v3.683c0 .401-.325.726-.725.726a.732.732 0 01-.733-.726V6.841zm.741 7.06a.734.734 0 01-.733-.734c0-.4.325-.725.725-.725.408 0 .733.325.733.725 0 .4-.325.733-.725.733z"
        fill="#404041"
        fillRule="evenodd"></path>
    </svg>
  );
}
