export function SearchEngineIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10.2721 1.84073C10.4051 1.72128 10.5812 1.65867 10.7614 1.6667C14.5704 1.78073 17.7537 4.54585 18.3307 8.24154C18.3343 8.26361 18.3343 8.28611 18.3307 8.30818C18.3431 8.48304 18.284 8.65555 18.1664 8.78756C18.0489 8.91956 17.8826 9.00017 17.7043 9.01157L11.3047 9.4336C11.0931 9.45236 10.8832 9.38272 10.7266 9.24178C10.57 9.10084 10.4813 8.90167 10.4821 8.69319L10.052 2.40711V2.30346C10.0598 2.12666 10.139 1.96018 10.2721 1.84073ZM9.83312 11.0773L15.3582 10.7367H15.4177C15.6558 10.7406 15.8827 10.8372 16.0483 11.0052C16.2139 11.1732 16.3047 11.3989 16.3006 11.6326C16.082 14.8177 13.7469 17.4789 10.5691 18.1645C7.39123 18.8502 4.13356 17.3955 2.57323 14.5943C2.11443 13.7874 1.8245 12.8988 1.72046 11.9806C1.68011 11.7085 1.66244 11.4337 1.66763 11.1588C1.67815 7.7719 4.08948 4.84857 7.46347 4.13227C7.87192 4.05582 8.28143 4.25975 8.45963 4.62834C8.54767 4.76108 8.57048 4.9236 8.58086 5.08254C8.63927 5.97761 8.69948 6.86524 8.75947 7.74944C8.81106 8.50996 8.86248 9.26795 8.91243 10.0259C8.90985 10.2045 8.93792 10.3822 8.99544 10.5516C9.13087 10.885 9.46788 11.0965 9.83312 11.0773Z"
        fill="#404041"
        fillRule="evenodd"
      />
    </svg>
  );
}
