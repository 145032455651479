import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Layout } from 'antd';

import { PageSidebar } from 'components';
import { PageHeader } from 'components/page-header';
import { useAuthStore } from 'store/auth';

import { LayoutProps } from '../types';

export function DashboardLayout({ children }: LayoutProps) {
  const { pathname, state } = useLocation();

  const { user } = useAuthStore((authState) => authState);

  if (!user) {
    return (
      <Navigate
        state={{
          from: pathname,
          search: state?.fromSearch,
        }}
        to="/auth/login"
      />
    );
  }

  return (
    <div className="hk-cms">
      <PageHeader />
      <Layout className="hk-cms-layout">
        <PageSidebar />
        {children ?? <Outlet />}
      </Layout>
    </div>
  );
}
