import { ThemeConfig } from 'antd';

const primaryGreen = '#007861';
const secondaryGreen = '#79c043';
const black = '#404041';

const inputSizeObj = {
  controlHeight: 44,
  fontSize: 14,
  paddingSM: 15,
};

export const theme: ThemeConfig = {
  token: {
    colorPrimary: primaryGreen,
    borderRadius: 8,
    fontFamily: 'Work Sans',
    colorText: black,
  },
  components: {
    Layout: {
      colorBgHeader: '#fff',
      colorBgBody: '#fff',
    },
    Menu: {
      itemSelectedBg: primaryGreen,
      itemSelectedColor: '#fff',
      subMenuItemBg: '#fff',
      itemHoverColor: '#fff',
      itemHoverBg: primaryGreen,
      colorBgTextActive: primaryGreen,
      itemBg: 'black',
    },
    Button: {
      colorPrimary: primaryGreen,
      controlHeight: 48,
      colorLink: primaryGreen,
      colorLinkHover: primaryGreen,
      colorLinkActive: primaryGreen,
      colorBgContainer: secondaryGreen,
      colorText: '#fff',
    },
    InputNumber: {
      controlHeight: 48,
    },
    Typography: {
      fontSizeHeading2: 32,
      titleMarginTop: 0,
      titleMarginBottom: 0,
    },
    Avatar: {
      containerSizeLG: 56,
    },
    Table: {
      fontSize: 16,
      fontWeightStrong: 500,
      colorBgContainer: '#F2F2F2',
      borderRadiusLG: 0,
      colorSplit: '#1677ff',
    },
    Breadcrumb: {
      fontSize: 16,
      separatorColor: '#BBC9D4',
      itemColor: '#BBC9D4',
      linkColor: black,
    },
    Input: inputSizeObj,
    Select: inputSizeObj,
    DatePicker: inputSizeObj,
    Form: {
      paddingXS: 12,
    },
    Tabs: {
      inkBarColor: primaryGreen,
      itemSelectedColor: primaryGreen,
      colorText: '#A4A4A4',
      itemHoverColor: primaryGreen,
    },
    Badge: {
      colorError: secondaryGreen,
      colorErrorHover: secondaryGreen,
      colorBorderBg: 'unset',
    },
    Divider: {
      marginLG: 32,
    },
  },
};
