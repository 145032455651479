export function ConsultationIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6.508 1.667h6.984c2.575 0 4.008 1.483 4.008 4.025V14.3c0 2.583-1.433 4.033-4.008 4.033H6.508c-2.533 0-4.008-1.45-4.008-4.033V5.692c0-2.542 1.475-4.025 4.008-4.025zm.225 3.883v-.008h2.491c.36 0 .651.291.651.649 0 .367-.292.659-.65.659H6.732a.65.65 0 010-1.3zm0 5.067h6.534a.65.65 0 000-1.301H6.733a.651.651 0 000 1.3zm0 3.808h6.534a.652.652 0 00.583-.65.654.654 0 00-.583-.658H6.733a.662.662 0 00-.625 1.008.647.647 0 00.625.3z"
        fill="#404041"
        fillRule="evenodd"></path>
    </svg>
  );
}
