export function UserManagementIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M11.844 6.469c0 2.203-1.803 3.968-4.053 3.968-2.249 0-4.052-1.765-4.052-3.968C3.739 4.266 5.542 2.5 7.79 2.5c2.25 0 4.053 1.766 4.053 3.969zM1.667 14.93c0-2.04 2.82-2.55 6.124-2.55 3.321 0 6.124.528 6.124 2.57 0 2.039-2.82 2.549-6.124 2.549-3.321 0-6.124-.528-6.124-2.569zm11.81-8.39a5.306 5.306 0 01-.947 3.038c-.062.09-.007.21.102.23.152.024.308.04.467.042 1.582.041 3.001-.956 3.393-2.458.582-2.229-1.124-4.23-3.297-4.23-.236 0-.462.024-.682.068-.03.006-.063.02-.079.046-.021.033-.006.075.016.103a5.344 5.344 0 011.028 3.16zm2.62 4.878c1.064.203 1.763.618 2.052 1.222.246.495.246 1.07 0 1.565-.443.936-1.871 1.237-2.426 1.315a.172.172 0 01-.195-.194c.284-2.593-1.973-3.823-2.556-4.106-.025-.013-.03-.032-.028-.045.001-.008.012-.02.03-.023 1.264-.024 2.622.146 3.124.266z"
        fill="#404041"
        fillRule="evenodd"></path>
    </svg>
  );
}
