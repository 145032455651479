import './style.scss';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Layout, Row } from 'antd';

import { ReactComponent as HukumkuLogo } from 'assets/svg/header-logo.svg';

export function PageHeader() {
  return (
    <Layout.Header className="hk-header">
      <Row align="middle" className="h-full" justify="space-between">
        <div className="hk-header-logo">
          <HukumkuLogo />
        </div>
        <Avatar icon={<UserOutlined />} size="large" />
      </Row>
    </Layout.Header>
  );
}
