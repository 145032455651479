import { Link } from 'react-router-dom';

import { ItemType } from 'antd/es/menu/hooks/useItems';

import { AdministratorIcon } from 'assets/icons/AdministratorIcon';
import { ConsultationIcon } from 'assets/icons/ConsultationIcon';
import { DashboardIcon } from 'assets/icons/DashboardIcon';
import { PromoIcon } from 'assets/icons/PromoIcon';
import { ReportIcon } from 'assets/icons/ReportIcon';
import { SearchEngineIcon } from 'assets/icons/SearchEngineIcon';
import { TransactionIcon } from 'assets/icons/TransactionIcon';
import { UserManagementIcon } from 'assets/icons/UserManagementIcon';

const submenuItemStyle: React.CSSProperties = { paddingLeft: 44 };

export const MENU_SIDEBAR_ITEMS = ({
  report,
}: {
  report: number | null | undefined;
}) => {
  const menuItems: ItemType[] = [
    {
      key: 'dashboard',
      label: <Link to="/dashboard">Dashboard</Link>,
      icon: <DashboardIcon />,
    },
    {
      key: 'administrator',
      label: <Link to="/administrator">Administrator</Link>,
      icon: <AdministratorIcon />,
    },
    {
      key: 'promo',
      label: <Link to="/promo">Promo</Link>,
      icon: <PromoIcon />,
    },
    {
      key: 'user-management',
      label: 'Manajemen Pengguna',
      icon: <UserManagementIcon />,
      children: [
        {
          key: 'lawyer',
          label: <Link to="/user-management/lawyer">Advokat</Link>,
          style: submenuItemStyle,
        },
        {
          key: 'client',
          label: <Link to="/user-management/client">Klien</Link>,
          style: submenuItemStyle,
        },
      ],
    },
    {
      key: 'consultation',
      label: 'Manajemen Konsultasi',
      icon: <ConsultationIcon />,
      children: [
        {
          key: 'package',
          label: <Link to="/consultation/package">Detail Paket</Link>,
          style: submenuItemStyle,
        },
        {
          key: 'detail-cases',
          label: <Link to="/consultation/detail-cases">Detail Kasus</Link>,
          style: submenuItemStyle,
        },
        {
          key: 'lawyer-price',
          label: (
            <Link to="/consultation/lawyer-price">Daftar Harga Advokat</Link>
          ),
          style: submenuItemStyle,
        },
      ],
    },
    {
      key: 'transaction-management',
      label: 'Manajemen Transaksi',
      icon: <TransactionIcon />,
      children: [
        {
          key: 'transaction-history',
          label: (
            <Link to="/transaction-management/history">Riwayat Transaksi</Link>
          ),
          style: submenuItemStyle,
        },
        {
          key: 'withdraw',
          label: <Link to="/transaction-management/withdraw">Penarikan</Link>,
          style: submenuItemStyle,
        },
      ],
    },
    {
      key: 'report',
      label: (
        <Link to="/report">
          <div className="report-wrapper">
            <span>Manajemen Report</span>
            <div className="report-number">
              <span>{report}</span>
            </div>
          </div>
        </Link>
      ),
      icon: <ReportIcon />,
    },
    {
      key: 'search-engine',
      label: <Link to="/search-engine">Search Engine</Link>,
      icon: <SearchEngineIcon />,
    },
  ];

  return menuItems;
};
