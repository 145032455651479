interface IProps {
  isActive?: boolean;
}

export function DashboardIcon({ isActive = false }: IProps) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.891 1.667c2.834 0 4.434 1.607 4.443 4.442v7.783c0 2.833-1.609 4.442-4.443 4.442H6.11c-2.835 0-4.442-1.609-4.442-4.442V6.109c0-2.835 1.607-4.442 4.442-4.442h7.782zm-3.474 3.442a.7.7 0 00-1.068.658v8.492a.702.702 0 00.692.625c.36 0 .659-.267.692-.625V5.767a.68.68 0 00-.316-.658zM6.525 7.842a.686.686 0 00-.733 0 .7.7 0 00-.325.658v5.759a.688.688 0 00.69.625c.36 0 .66-.267.692-.625V8.5a.702.702 0 00-.324-.658zm7.716 3.025a.699.699 0 00-.74 0 .671.671 0 00-.317.658v2.734a.689.689 0 00.691.625c.35 0 .65-.267.692-.625v-2.734a.695.695 0 00-.326-.658z"
        fill={isActive ? '#fff' : '#404041'}></path>
    </svg>
  );
}
