import './style.scss';

import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Layout, Menu } from 'antd';

import { useGetAuthenticationsProviderLogout } from 'api/generated/services/auth/authentication/authentication';
import { LogoutIcon } from 'assets/icons/LogoutIcon';
import { useAuthStore } from 'store/auth';

import { MENU_SIDEBAR_ITEMS } from './constants';

export function PageSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [report, setReport] = useState<number | null>(0);

  const { reset } = useAuthStore((state) => state.actions);

  const { refetch: logout, isRefetching: isLoadingLogout } =
    useGetAuthenticationsProviderLogout('direct', {
      query: {
        enabled: false,
      },
    });

  const pathNames = useMemo(() => location?.pathname?.split('/'), [location]);
  const menuItems = useMemo(() => MENU_SIDEBAR_ITEMS({ report }), [report]);

  const defaultOpenKeys = useMemo(() => {
    if (pathNames?.length <= 2) {
      return [];
    }
    return [pathNames?.[1]];
  }, [pathNames]);

  const defaultSelectedKeys = useMemo(() => {
    if (pathNames?.length <= 2) {
      return [pathNames?.[1]];
    }
    return [pathNames?.[2]];
  }, [pathNames]);

  async function handleLogout() {
    await logout();
    reset();
    navigate('/auth/login');
  }

  return (
    <Layout.Sider className="hk-sidebar" width="264">
      <div className="hk-sidebar-wrapper">
        <div className="hk-menu">
          <Menu
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            inlineIndent={12}
            items={menuItems}
            mode="inline"
          />
        </div>
        <div className="hk-logout">
          <Button
            className="hk-logout-btn"
            icon={<LogoutIcon />}
            loading={isLoadingLogout}
            onClick={handleLogout}
            type="link">
            Keluar
          </Button>
        </div>
      </div>
    </Layout.Sider>
  );
}
