import { SelectProps } from 'antd';

import { LawyerStatusUIConfiguration, TApi, TServices } from 'types';
import { LawyerStatus } from 'types/enums';

export const DEBOUNCE_DEFAULT_DURATION_MILLISECONDS = 600;

export const SERVICES: Record<TApi, TServices> = {
  AUTH: 'auth',
  CASE: 'case',
  DOCUMENT: 'document',
  OFFER: 'offer',
};

export const ADMIN_TYPE_OPTIONS: SelectProps['options'] = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
];

export const LAWYER_STATUS_UI_CONFIGURATION: Record<
  string,
  LawyerStatusUIConfiguration
> = {
  [LawyerStatus.pending]: { color: 'yellow' },
  [LawyerStatus.in_review]: { color: 'yellow' },
  [LawyerStatus.approved]: { color: 'green' },
  [LawyerStatus.rejected]: { color: undefined },
  [LawyerStatus.totally_rejected]: { color: undefined },
  [LawyerStatus.temporary_suspended]: { color: 'orange' },
  [LawyerStatus.permanent_suspended]: { color: 'red' },
};

export const GENDER_MAP: Record<string, string> = {
  male: 'Lak-laki',
  female: 'Perempuan',
};

export const CONSULTATION_TYPE_MAP: Record<string, string> = {
  chat: 'Pesan Singkat',
};
