import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { LAWYER_STATUS_UI_CONFIGURATION } from 'config/constants';

import { UserTagStatus } from './types';

export function TagUser({ status }: UserTagStatus) {
  const { t } = useTranslation(['lawyer']);
  const { color } = LAWYER_STATUS_UI_CONFIGURATION[status] || { color: '' };

  return (
    <Tag bordered={false} color={color}>
      {t(`lawyer:status.${status}`)}
    </Tag>
  );
}
