import { Col, Row } from 'antd';

import FormErrorAlert from 'assets/svg/form-error.svg';

interface FormErrorProps {
  message: string;
}

export function FormError({ message }: FormErrorProps) {
  return (
    <Row align="middle" justify="start">
      <Col className="flex center hk-margin-right-xs">
        <img className="object-fit" src={FormErrorAlert} />
      </Col>
      <Col>{message}</Col>
    </Row>
  );
}
