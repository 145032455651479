export enum DocumentType {
  lawyer_certificate = 'lawyer_certificate',
  profile_picture = 'profile_picture',
  lawyer_identity_card = 'lawyer_identity_card',
  lawyer_advocate_license = 'lawyer_advocate_license',
}

export enum LawyerStatus {
  pending = 'pending',
  in_review = 'in_review',
  approved = 'approved',
  rejected = 'rejected',
  totally_rejected = 'totally_rejected',
  temporary_suspended = 'temporary_suspended',
  permanent_suspended = 'permanent_suspended',
}

export enum LawyerRejectedReason {
  fake_id = 'fake_id',
  fake_account = 'fake_account',
  other_reason = 'other_reason',
}
