export function LogoutIcon() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M11.308 2a4.441 4.441 0 014.436 4.436v.932a.75.75 0 01-1.5 0v-.932A2.939 2.939 0 0011.308 3.5H6.433A2.937 2.937 0 003.5 6.436v11.129A2.937 2.937 0 006.433 20.5h4.886a2.929 2.929 0 002.925-2.924v-.943a.75.75 0 011.5 0v.943A4.43 4.43 0 0111.32 22H6.433A4.439 4.439 0 012 17.565V6.436A4.44 4.44 0 016.433 2h4.875zm8.08 6.554l2.928 2.915a.74.74 0 01.071.081l-.07-.081a.74.74 0 01.22.531l-.005.062-.002.04v.005l.007-.107a.748.748 0 01-.227.538l-2.922 2.909a.747.747 0 01-1.06-.002.75.75 0 01.002-1.06l1.64-1.635H9.746a.75.75 0 010-1.5h10.226L18.33 9.616a.749.749 0 111.058-1.062z"
        fill="#007861"
        fillRule="evenodd"></path>
    </svg>
  );
}
