import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ConfigProvider, message } from 'antd';

import { Error } from 'api/generated/models/auth';
import { theme } from 'config/antd-theme';
import { useAuthStore } from 'store/auth';
export interface AppProvidersProps {
  children: ReactNode;
}

export function AppProviders({ children }: AppProvidersProps) {
  const { t } = useTranslation(['error-message']);
  const navigate = useNavigate();
  const { reset: resetAuth } = useAuthStore((state) => state.actions);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      // TODO: Error type
      onError: (error: any, query: any) => {
        if (error?.response?.status === 401) {
          resetAuth();
          navigate('/auth/login');
          return;
        }

        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.status === 'error') {
          return message.error(`Something went wrong: ${error.message}`);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        const errorResponse = error.response.data as Error;
        if (errorResponse?.detail?.length) {
          errorResponse.detail.forEach((eachError) => {
            const errorMessage = t(eachError.code);
            return message.error(errorMessage);
          });
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>{children}</ConfigProvider>
    </QueryClientProvider>
  );
}
