import { message } from 'antd';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';

import { SERVICES } from 'config/constants';

import { IGenerateUrlPathProps } from './types';

/**
 *
 * @param {Object} errorBE - error message from BE
 * @param {String} customError - error message from FE
 */
export function errHandler(
  errorBE: Record<string, any> | undefined = {},
  customError: string = ''
) {
  const {
    response: {
      data: {
        message:
          errorMessageFromBE = 'Cannot connect to server, please check connection',
      } = {},
    } = {},
  } = errorBE ?? {};
  message.error(customError || errorMessageFromBE);
}

export function generateUrlPath(props: IGenerateUrlPathProps) {
  const { service, path, version = 'v1' } = props ?? {};

  return `/${SERVICES[service]}/${version}/${path?.replace(/^\//, '')}`;
}

export function errorHandler(error: unknown) {
  if (isAxiosError(error)) {
    return message.error(error.message);
  }
}

export function priceFormatter(number: number | string) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function currencyFormatter(amount: number, currency = 'Rp') {
  const formatter = priceFormatter(amount);
  return currency + formatter;
}

export const unixToDate = (unix: number, format: string) => {
  if (!unix) return '-';
  return dayjs.unix(unix).format(format);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
