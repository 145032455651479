import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DashboardLayout } from 'components/layouts/dashboard-layout';
import { DashboardLayoutLoading } from 'components/placeholder-loadings/dashboard-layout-loading';
import { lazyNamedExport } from 'utils/lazy-named-export';

const Dashboard = lazyNamedExport(
  import(/* webpackChunkName: "Dashboard" */ 'pages/dashboard'),
  'Dashboard'
);
const Administrator = lazyNamedExport(
  import(/* webpackChunkName: "Administrator" */ 'pages/administrator'),
  'Administrator'
);
const AuthRoutes = lazyNamedExport(
  import(/* webpackChunkName: "AuthRoutes" */ './Auth'),
  'AuthRoutes'
);
const ConsultationManagementRoutes = lazyNamedExport(
  import(
    /* webpackChunkName: "ConsultationManagementRoutes" */ './ConsultationManagement'
  ),
  'ConsultationManagementRoutes'
);
const TransactionManagementRoutes = lazyNamedExport(
  import(
    /* webpackChunkName: "TransactionManagementRoutes" */ './TransactionManagement'
  ),
  'TransactionManagementRoutes'
);
const UserManagementRoutes = lazyNamedExport(
  import(/* webpackChunkName: "UserManagementRoutes" */ './UserManagement'),
  'UserManagementRoutes'
);
const PromoRoutes = lazyNamedExport(
  import(/* webpackChunkName: "PromoRoutes" */ './Promo'),
  'PromoRoutes'
);
const SearchEngine = lazyNamedExport(
  import(/* webpackChunkName: "SearchEngine" */ 'pages/search-engine'),
  'SearchEngine'
);
const NotFoundError = lazyNamedExport(
  import(
    /* webpackChunkName: "NotFoundError" */ 'components/placeholder-errors/not-found-error'
  ),
  'NotFoundError'
);
const PlaceholderUnderConstruction = lazyNamedExport(
  import(
    /* webpackChunkName: "NotFoundError" */ 'components/placeholder-under-construction'
  ),
  'PlaceholderUnderConstruction'
);

export const AppRoutes = () => {
  return (
    <Routes>
      {/* Non Logged in Routes */}
      <Route path="/auth/*" element={<AuthRoutes />} />

      {/* Logged in Routes */}
      <Route
        path="/*"
        element={
          <Suspense fallback={<DashboardLayoutLoading />}>
            <Routes>
              <Route path="/" element={<DashboardLayout />}>
                <Route path="" element={<Navigate to="dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="administrator" element={<Administrator />} />
                <Route
                  path="user-management/*"
                  element={<UserManagementRoutes />}
                />

                <Route
                  path="transaction-management/*"
                  element={<TransactionManagementRoutes />}
                />

                <Route
                  path="consultation/*"
                  element={<ConsultationManagementRoutes />}
                />
                <Route path="promo/*" element={<PromoRoutes />} />

                <Route
                  path="report"
                  element={<PlaceholderUnderConstruction />}
                />
                <Route path="search-engine" element={<SearchEngine />} />

                {/* End of under construction routes */}

                <Route path="" element={<NotFoundError />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>
            </Routes>
          </Suspense>
        }
      />
    </Routes>
  );
};
