export function AdministratorIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14.412 6.076A4.395 4.395 0 0110 10.486a4.396 4.396 0 01-4.412-4.41A4.395 4.395 0 0110 1.666a4.394 4.394 0 014.412 4.41zM10 18.333c-3.615 0-6.667-.587-6.667-2.854 0-2.267 3.071-2.834 6.667-2.834 3.616 0 6.667.587 6.667 2.854 0 2.268-3.072 2.834-6.667 2.834z"
        fill="#404041"
        fillRule="evenodd"></path>
    </svg>
  );
}
