export function TransactionIcon() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14.807 6.985h3.526c0-2.831-1.696-4.485-4.57-4.485H6.237c-2.874 0-4.57 1.654-4.57 4.449v6.102c0 2.795 1.696 4.449 4.57 4.449h7.526c2.874 0 4.57-1.654 4.57-4.449v-.26h-3.526c-1.636 0-2.963-1.293-2.963-2.889 0-1.595 1.327-2.888 2.963-2.888v-.03zm0 1.242h2.904c.344 0 .622.272.622.607v2.109a.622.622 0 01-.622.606h-2.837c-.828.011-1.553-.542-1.74-1.329a1.652 1.652 0 01.36-1.376c.323-.385.804-.61 1.313-.617zm.126 2.217h.274a.63.63 0 00.637-.62.63.63 0 00-.637-.622h-.274a.638.638 0 00-.45.177.606.606 0 00-.187.437c0 .344.284.624.637.628zm-9.318-3.46h4.704a.63.63 0 00.636-.62.63.63 0 00-.636-.621H5.615a.63.63 0 00-.637.614c0 .344.284.624.637.628z"
        fill="#404041"
        fillRule="evenodd"></path>
    </svg>
  );
}
