import React from 'react';

export function SearchIcon() {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M7.826 1.333a6.499 6.499 0 016.492 6.492c0 1.69-.649 3.23-1.71 4.386l2.088 2.083a.5.5 0 11-.707.708l-2.112-2.107a6.461 6.461 0 01-4.051 1.423 6.5 6.5 0 01-6.493-6.493 6.5 6.5 0 016.493-6.492zm0 1a5.498 5.498 0 00-5.493 5.492 5.499 5.499 0 005.493 5.493 5.499 5.499 0 005.492-5.493 5.498 5.498 0 00-5.492-5.492z"
        fill="#787872"
        fillRule="evenodd"></path>
    </svg>
  );
}
