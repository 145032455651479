import './style.scss';

import { Typography } from 'antd';

interface IProps {
  label: string;
}

export function OptionalLabel({ label }: IProps) {
  return (
    <Typography.Text>
      {label} <span className="gray">(Optional)</span>
    </Typography.Text>
  );
}
