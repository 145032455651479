interface IProps {
  isActive?: boolean;
}

export function PromoIcon({ isActive = false }: IProps) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M18.1561 8.7827C18.0433 8.89231 17.8902 8.95495 17.729 8.95495C17.1326 8.95495 16.649 9.42471 16.649 9.99625C16.649 10.5717 17.1269 11.0391 17.7177 11.0454C18.0505 11.0485 18.3334 11.274 18.3334 11.5973V13.6056C18.3334 15.2959 16.923 16.6668 15.1822 16.6668H12.5549C12.2833 16.6668 12.0633 16.4531 12.0633 16.1892V14.4981C12.0633 14.1693 11.7973 13.9109 11.4588 13.9109C11.1284 13.9109 10.8544 14.1693 10.8544 14.4981V16.1892C10.8544 16.4531 10.6344 16.6668 10.3636 16.6668H4.81794C3.08519 16.6668 1.66675 15.2967 1.66675 13.6056V11.5973C1.66675 11.274 1.94963 11.0485 2.28248 11.0454C2.87403 11.0391 3.35115 10.5717 3.35115 9.99625C3.35115 9.44037 2.8837 9.01758 2.2712 9.01758C2.11001 9.01758 1.95688 8.95495 1.84405 8.84534C1.73122 8.73573 1.66675 8.58697 1.66675 8.43038V6.40259C1.66675 4.71459 3.08841 3.3335 4.826 3.3335H10.3636C10.6344 3.3335 10.8544 3.54724 10.8544 3.81108V5.81539C10.8544 6.13639 11.1284 6.40259 11.4588 6.40259C11.7973 6.40259 12.0633 6.13639 12.0633 5.81539V3.81108C12.0633 3.54724 12.2833 3.3335 12.5549 3.3335H15.1822C16.923 3.3335 18.3334 4.70363 18.3334 6.39476V8.36775C18.3334 8.52434 18.2689 8.67309 18.1561 8.7827ZM11.4588 12.392C11.7973 12.392 12.0633 12.1258 12.0633 11.8048V8.67309C12.0633 8.35209 11.7973 8.08589 11.4588 8.08589C11.1284 8.08589 10.8544 8.35209 10.8544 8.67309V11.8048C10.8544 12.1258 11.1284 12.392 11.4588 12.392Z"
        fill={isActive ? '#fff' : '#404041'}
        fillRule="evenodd"
      />
    </svg>
  );
}
