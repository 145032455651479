import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { IAuthState, IInitialAuth } from './types/auth';

const initialState: IInitialAuth = {
  username: undefined,
  email: undefined,
  roles: undefined,
  user: undefined,
};

export const useAuthStore = create<IAuthState>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        setAuth: (payload) => {
          set((state) => ({ ...state, ...payload }));
        },
        reset: () => {
          set(() => initialState);
        },
      },
    }),
    {
      name: 'auth',
      partialize: (state) => {
        // define keys from state that dont want to be included in persist storage
        const excludedKeys = ['actions'];
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !excludedKeys.includes(key))
        );
      },
    }
  )
);
