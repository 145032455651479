import './style.scss';

import { Button as AntdButton, ButtonProps } from 'antd';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

export function Button({ children, className = '', ...props }: IProps) {
  return (
    <AntdButton className={`hk-button ${className}`} {...props}>
      {children}
    </AntdButton>
  );
}
